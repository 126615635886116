<template>
    <div class="home mt-3">
        <v-card>
            <v-dialog v-model="modalInspeccion" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">ss</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="validoInspeccion" v-model="validoInspeccion">
                                <v-text-field v-model="editedInspeccion.nombre" :rules="reglaNombre" label="Nombre"
                                    outlined clearable required>
                                </v-text-field>
                                <v-dialog ref="dialogDate" v-model="modal" :return-value.sync="editedInspeccion.fecha"
                                    persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editedInspeccion.fecha" :rules="reglaFecha"
                                            prepend-inner-icon="mdi-calendar-today" label="Fecha de inspección" readonly
                                            v-bind="attrs" v-on="on" outlined clearable required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedInspeccion.fecha" scrollable locale="es">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modal = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.dialogDate.save(editedInspeccion.fecha)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                                <v-file-input v-model="editedInspeccion.documento" :rules="reglaDocumento" accept="application/pdf" prepend-icon="" prepend-inner-icon="" label="Documento*" outlined required>
                                    <template v-slot:selection="{ text }">
                                        <v-chip small label color="primary">
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoInspeccion" color="success" class="mr-4">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-card-title>
                <v-toolbar-title>
                    {{titulo}}
                </v-toolbar-title>
                <v-divider class="mx-3" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar:" outlined hide-details
                    clearable>
                </v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="activosVencidos" :search="search" :footer-props="{
            showFirstLastPage: true, 
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-text': 'N° Paginas',
            'items-per-page-all-text':'Todos',
            pageText: '{1} de {2}',
            }" class="elevation-1">
                <template v-slot:item.numero="{index}">
                    {{ ++index  }}
                </template>
                <template v-slot:item.acciones="{ item }">
                    <v-btn small class="ma-1" color="warning" @click="li(item)">
                        <v-icon small color="white">
                            mdi-shield-check
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="listarItem()">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>

</template>

<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    export default {
        name: "Inicio",
        data: () => ({
            modalInspeccion: false,
            modal: false,
            validoInspeccion: false,
            reglaNombre: [
                v => !!v || 'El nombre es obligatorio.'
            ],
            reglaFecha: [
                v => !!v || 'La fecha es obligatorio.'
            ],
            reglaDocumento:[
                v => v || 'El Documento de e videncia es Requerido.',
            ],
            editedInspeccionIndex: -1,
            editedInspeccion: {
                nombre: '',
                nombre2: '',
                fecha: '',
                documento: null
            },
            defaultInspeccion: {
                nombre: '',
                nombre2: '',
                fecha: '',
                documento: null
            },
            /** */
            search: '',
            titulo: 'Para Inpeccionar',
            url_api: process.env.VUE_APP_URL_API,
            headers: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Codigo',
                    align: 'start',
                    value: 'codigo'
                },
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'descripcion'
                },
                {
                    text: 'Acciones',
                    align: 'start',
                    value: 'acciones'
                },
            ],
            activosVencidos: [],
        }),

        computed: {
            ...mapState(['token']),
            formTitle() {
                return this.editedIndex === -1 ? "New Item" : "Edit Item";
            },
        },

        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },

        created() {
            // this.initialize();
            this.listarItem();
        },

        methods: {
            ...mapActions(['cerrarSesion']),
            async listarItem() {
                try {
                    await fetch(`${this.url_api}/item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                var fechaActual = (new Date(Date.now() - (new Date()).getTimezoneOffset() *
                                    60000)).toISOString().substr(0, 10);
                                var fechaActua = fechaActual.match(/(\d+)/g);
                                response.body.forEach(element => {
                                    var fechaVence = element.vencimiento;
                                    var fechaVen = fechaVence.match(/(\d+)/g);
                                    if ((
                                            (new Date(fechaActua[0], fechaActua[1] - 1, fechaActua[2]).getTime()) 
                                            < 
                                            (new Date(fechaVen[0], fechaVen[1] - 1, fechaVen[2]).getTime())
                                        )) 
                                    {
                                        console.log(element.fechaCompra);
                                        this.activosVencidos.push(element);
                                    }
                                })
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            
            async li(item) {
                this.modalInspeccion = true;
                console.log(item);
            }
        },

    };
</script>