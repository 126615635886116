var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home mt-3"},[_c('v-card',[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.modalInspeccion),callback:function ($$v) {_vm.modalInspeccion=$$v},expression:"modalInspeccion"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("ss")])])],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"validoInspeccion",model:{value:(_vm.validoInspeccion),callback:function ($$v) {_vm.validoInspeccion=$$v},expression:"validoInspeccion"}},[_c('v-text-field',{attrs:{"rules":_vm.reglaNombre,"label":"Nombre","outlined":"","clearable":"","required":""},model:{value:(_vm.editedInspeccion.nombre),callback:function ($$v) {_vm.$set(_vm.editedInspeccion, "nombre", $$v)},expression:"editedInspeccion.nombre"}}),_c('v-dialog',{ref:"dialogDate",attrs:{"return-value":_vm.editedInspeccion.fecha,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedInspeccion, "fecha", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedInspeccion, "fecha", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.reglaFecha,"prepend-inner-icon":"mdi-calendar-today","label":"Fecha de inspección","readonly":"","outlined":"","clearable":"","required":""},model:{value:(_vm.editedInspeccion.fecha),callback:function ($$v) {_vm.$set(_vm.editedInspeccion, "fecha", $$v)},expression:"editedInspeccion.fecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es"},model:{value:(_vm.editedInspeccion.fecha),callback:function ($$v) {_vm.$set(_vm.editedInspeccion, "fecha", $$v)},expression:"editedInspeccion.fecha"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogDate.save(_vm.editedInspeccion.fecha)}}},[_vm._v(" OK ")])],1)],1),_c('v-file-input',{attrs:{"rules":_vm.reglaDocumento,"accept":"application/pdf","prepend-icon":"","prepend-inner-icon":"","label":"Documento*","outlined":"","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.editedInspeccion.documento),callback:function ($$v) {_vm.$set(_vm.editedInspeccion, "documento", $$v)},expression:"editedInspeccion.documento"}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.validoInspeccion,"color":"success"}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1),_c('v-card-title',[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c('v-divider',{staticClass:"mx-3",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar:","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activosVencidos,"search":_vm.search,"footer-props":{
        showFirstLastPage: true, 
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-text': 'N° Paginas',
        'items-per-page-all-text':'Todos',
        pageText: '{1} de {2}',
        }},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
        var index = ref.index;
return [_vm._v(" "+_vm._s(++index)+" ")]}},{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.li(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-shield-check ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listarItem()}}},[_vm._v(" Recargar ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }